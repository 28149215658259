import React from "react";

import { graphql } from "gatsby";

import { Seo, SortedCards, CollectionsLinks } from "../components";
import Layout from "../components/Layout";

const TITLE = "Shop";

const Shop = ({ data }) => {
  const books = data.all.nodes;

  return (
    <Layout>
      <Seo title={TITLE} />
      <CollectionsLinks />
      <SortedCards books={books} title={TITLE} />
    </Layout>
  );
};

export const query = graphql`
  {
    all: allAirtable(
      filter: {
        data: { status: { eq: "Available" }, categories: { ne: "Sold Out" } }
      }
      sort: { fields: data___Release_Date, order: DESC }
    ) {
      nodes {
        data {
          title
          artist
          SKU
          RRP
          categories
          cover {
            localFiles {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          gallery {
            localFiles {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

export default Shop;
